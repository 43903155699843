// Entry point for the build script in your package.json

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Ready handler
type ReadyHandler = () => void;

function handleState() {
  if (["interactive", "complete"].indexOf(document.readyState) > -1) {
    while (readyHandlers.length > 0) {
      readyHandlers.shift()();
    }
  }
}

const readyHandlers: ReadyHandler[] = [];
document.onreadystatechange = handleState;

function ready(handler: ReadyHandler) {
  readyHandlers.push(handler);
  handleState();
}

ready(function () {
  const header = document.getElementsByTagName("header")[0];
  const mobileToggle = header.querySelector(".mobile-toggle a");
  const searchToggle = header.querySelector(".search-toggle a");
  const searchInput = header.querySelector(".search-bar input[type=text]");

  // Toggle search
  searchToggle.addEventListener("click", function (evt: Event) {
    evt.preventDefault();
    header.classList.toggle("search");
    if (header.classList.contains("search")) {
      searchToggle.setAttribute("aria-expanded", "true");
      (searchInput as HTMLInputElement).focus();
    } else {
      searchToggle.setAttribute("aria-expanded", "false");
    }
  });

  // Toggle mobile
  mobileToggle.addEventListener("click", function (evt) {
    evt.preventDefault();
    header.classList.toggle("mobile-nav");
    if (header.classList.contains("mobile-nav")) {
      mobileToggle.setAttribute("aria-expanded", "true");
    } else {
      mobileToggle.setAttribute("aria-expanded", "false");
    }
  });
});
