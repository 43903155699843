import React, { useState } from "react";

interface Rate {
  id: string;
  label: string;
  rate: number;
}

interface State {
  rate: Rate;
  eur: string;
  nok: string;
}

interface Props {
  rates: Rate[];
}

function filterInput(str: string) {
  return str.replace(/[^\d.]/, "");
}

function formatNumber(i: number | string): string {
  if (Number.isNaN(i)) {
    return "";
  } else {
    return parseFloat(`${i}`).toFixed(4);
  }
}

export default function ExchangeRateCalculator(props: Props) {
  const rate = props.rates[0];
  const [state, setState] = useState<State>({
    rate: rate,
    eur: "1",
    nok: formatNumber(rate.rate)
  });

  const setEur = (value: string) => {
    const eur = filterInput(value);
    const nok = formatNumber(parseFloat(eur) * state.rate.rate);
    setState({ ...state, eur: eur, nok: nok });
  };

  const setNok = (value: string) => {
    const nok = filterInput(value);
    const eur = formatNumber(parseFloat(nok) / state.rate.rate);
    setState({ ...state, eur: eur, nok: nok });
  };

  const changeRate = (id: string) => {
    const rate = props.rates.filter((r) => r.id == id)[0];
    const nok = formatNumber(parseFloat(state.eur) * rate.rate);
    setState({ ...state, rate: rate, nok: nok });
  };

  return (
    <div className="calculator">
      <h2 className="small">Valutakurs</h2>
      <label htmlFor="publishedMonth">Velg publiseringsmåned</label>
      <div className="rate">
        <select
          id="publishedMonth"
          value={state.rate.id}
          onChange={(e) => changeRate(e.target.value)}>
          {props.rates.map((r) => (
            <option key={"rate-" + r.id} value={r.id}>
              {r.label}
            </option>
          ))}
        </select>
      </div>
      <div className="eur">
        <label htmlFor="euro">Euro</label>
        <input
          type="text"
          id="euro"
          onChange={(e) => setEur(e.target.value)}
          value={state.eur}
        />
      </div>
      <div className="nok">
        <label htmlFor="nok">NOK</label>
        <input
          type="text"
          id="nok"
          onChange={(e) => setNok(e.target.value)}
          value={state.nok}
        />
      </div>
      <p>
        <a href="https://ec.europa.eu/info/funding-tenders/procedures-guidelines-tenders/information-contractors-and-beneficiaries/exchange-rate-inforeuro_en">
          Tidligere eurokurser
        </a>
      </p>
    </div>
  );
}
